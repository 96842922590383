<template lang="html" src="./pageAdministration.template.vue"></template>

<style lang="scss" src="./pageAdministration.scss"></style>

<script>
import { EventBus } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageAdministration.i18n');

export default {
  name: 'PageAdministration',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      tabs: [
        {
          name: this.$t('General'),
          route: { name: 'AdministrationGeneral' },
          icon: 'fas fa-globe',
        },
        {
          name: this.$t('Subscription'),
          route: { name: 'AdministrationSubscription' },
          icon: 'fas fa-file-signature',
        },
        {
          name: this.$t('PaymentMethods'),
          route: { name: 'AdministrationPaymentMethods' },
          icon: 'fas fa-credit-card',
        },
        {
          name: this.$t('Users'),
          route: { name: 'AdministrationUsers' },
          icon: 'fas fa-users',
        },
      ],
    };
  },
  updated() {
    this.checkDefaultRoute();
  },
  created() {
    this.checkDefaultRoute();
    if (!this.$appConfig.brand) {
      EventBus.$emit('reloadBrand');
    }
    if (this.$appConfig.brand.features.startup && this.$appConfig.brand.features.startup.isManager) {
      this.tabs.push({
        name: this.$t('managedStartups'),
        route: { name: 'AdministrationManagedStartups' },
        icon: 'fas fa-warehouse',
      });
    }
    if (this.$appConfig.brand.features.sharing && this.$appConfig.brand.features.sharing.enabled) {
      this.tabs.push({
        name: this.$t('sharingParts'),
        route: { name: 'AdministrationSharingParts' },
        icon: 'fas fa-share-alt',
      });
    }

  },
  methods: {
    checkDefaultRoute() {
      if(this.$route.name === 'Administration') {
        this.$router.push(this.tabs[0].route);
      }
    },
  }
};
</script>
